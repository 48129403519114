<template>
    <div>
        <img src="https://seetop-1257860468.file.myqcloud.com/html/img/bind-top.png" style="width: 100vw">
        <div class="item">
            <div class="left">
                <i class="iconfont icon-shouji"></i>
            </div>
            <div class="middle">
                <div>账</div>
                <div>号：</div>
            </div>
            <div class="right">
                <input type="text"  placeholder="请输入账号" v-model="account" name="account"/>
            </div>
        </div>
        <div class="item">
            <div class="left">
                <i class="iconfont icon-shouji"></i>
            </div>
            <div class="middle">
                <div>密</div>
                <div>码：</div>
            </div>
            <div class="right">
                <input type="password"  placeholder="请输入密码" v-model="password" name="password"/>
            </div>
        </div>
        <input class="sure" value="登录" @click="login()">
    </div>
</template>
<script>
  import { globalConfig } from '../../utils/mixin'
  export default {
    name: 'Login',
    mixins: [globalConfig],
    components: {},
    data () {
      return {
        account: '',
        password: ''
      }
    },
    methods: {
      init: function () {
        this.axios.get(this.apiUrl + 'checkh5/api/init').then((response) => {
          console.log(response)
          const data = response.data
          if (data.status === 1) {
            this.$router.replace('/check/index')
          } else {
            console.log('初始化成功')
          }
        })
      },
      login: function () {
        console.log(this.account)
        this.axios.post(this.apiUrl + 'checkh5/login/login', {
          account: this.account,
          password: this.password
        }).then((response) => {
          console.log(response)
          const data = response.data
          if (data.status === 1) {
            this.$router.replace('/check/index')
          } else {
            this.$toast(data.msg)
          }
        })
      }
    },
    created () {
      this.init()
    },
    mounted () {
    },
    computed: {
    }
  }
</script>
<style scoped>
    body{
        box-sizing: border-box;
    }
    .sure{
        display: block;
        width:70%;
        color: #ffffff;
        margin:3rem auto 0;
        background-color: #ca1416;
        height:3rem;
        line-height:3rem;
        border-radius:10px;
        text-align: center;
        font-size: 1.1rem;
    }
    .sure input{
        font-size: 1.1rem;
        color: white;
        text-align: center;
    }
    .sure input{
        outline: none;
        border: 0;
        font-size: 1rem;
        background-color: transparent;
        color: white;
    }
    .choose p{
        color: #2a2a2a;
        font-size: 1.2rem;
        padding: 1.3rem 0 0.8rem;
    }
    .item{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
    }
    .left{
        width: 10%;
    }
    .left i{
        color:  #ca1416;
        font-size: 1.7rem;
    }
    .middle{
        width:19%;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    .middle{
        color:  #ca1416;
        font-size: 1.1rem;
    }
    .right{
        width: 62%;
    }
    .right input{
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid #ca1416;
        border-radius:0.6rem;
        height: 2.4rem;
        width: 100%;
        text-indent: 0.5rem;
        font-size: 1rem;
        outline:none;
    }
</style>
